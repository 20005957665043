import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import RouterWrapper from './RouterWrapper';

import Login from '../pages/Login';
import Advogados from '../pages/Advogados';
import Secretarias from '../pages/Secretarias';
import Administradores from '../pages/Administradores';
import Varas from '../pages/Varas';
import Clientes from '../pages/Clientes';
import Cliente from '../pages/Cliente';
import Processos from '../pages/Processos';
import Processo from '../pages/Processo';
import Agenda from '../pages/Agenda';

const Router = () => (
  <Routes>
    <Route exact path="*" element={<RouterWrapper />}>
      <Route exact path="*" element={<Login />} />
    </Route>
    <Route exact path="/login" element={<RouterWrapper />}>
      <Route exact path="/login" element={<Login />} />
    </Route>
    <Route exact path="/" element={<PrivateRoute />}>
      <Route exact path="/" element={<Processos />} />
    </Route>
    <Route exact path="/advogados" element={<PrivateRoute />}>
      <Route exact path="/advogados" element={<Advogados />} />
    </Route>
    <Route exact path="/administradores" element={<PrivateRoute />}>
      <Route exact path="/administradores" element={<Administradores />} />
    </Route>
    <Route exact path="/secretarias" element={<PrivateRoute />}>
      <Route exact path="/secretarias" element={<Secretarias />} />
    </Route>
    <Route exact path="/varas" element={<PrivateRoute />}>
      <Route exact path="/varas" element={<Varas />} />
    </Route>
    <Route exact path="/clientes" element={<PrivateRoute />}>
      <Route exact path="/clientes" element={<Clientes />} />
    </Route>
    <Route exact path="/agenda" element={<PrivateRoute />}>
      <Route exact path="/agenda" element={<Agenda />} />
    </Route>
    <Route exact path="/cliente/:id" element={<PrivateRoute />}>
      <Route exact path="/cliente/:id" element={<Cliente />} />
    </Route>
    <Route exact path="/processo/:id" element={<PrivateRoute />}>
      <Route exact path="/processo/:id" element={<Processo />} />
    </Route>
  </Routes>
);

export default Router;
