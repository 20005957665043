import React, { useEffect, useState } from 'react';
import {
 MdAdd, MdDelete, MdEdit, MdBalance
} from 'react-icons/md';
import CountUp from 'react-countup';
import { ExclamationCircleFilled } from '@ant-design/icons';

import api from '../../services/api';
import { Container, Cards, Card, Title, ViewInput, Table, Scroll} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination } from 'antd';

const { confirm } = Modal;

const Varas = () => {

  const [varas, setVaras] = useState([]);
  const [varasTotal, setVarasTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("")
  const [judge, setJudge] = useState("")
  const [page, setPage] = useState(0)
  const [id, setId] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  useEffect(() => {
    loadVaras()
  }, [page]);

  async function loadVaras() {
   await api.get(`/varas?page=${page}`).then((response) => {
     setVaras(response.data.varas)
     setVarasTotal(response.data.varasTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function createVara() {
    setIsLoadingModal(true)
    if (!name || !judge){
      toast.warning("Nome da vara e do juiz são obrigatórios");
    } else {
      await api.post(`/vara`, { name: name, judge: judge }).then((response) => {
        toast.success("Vara criada com sucesso")
        setIsOpen(false)
        loadVaras()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editVara() {
    setIsLoadingModal(true)
    if (!name || !judge){
      toast.warning("Nome da vara e do juiz são obrigatórios");
    } else {
      await api.put(`/vara/${id}`, { name: name, judge: judge }).then((response) => {
        toast.success("Vara editada com sucesso")
        setIsOpen(false)
        loadVaras()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteVara(vara) {
    await api.delete(`/vara/${vara.id}`).then(response => {
      toast.success("Vara deletada com sucesso")
      loadVaras()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  return (
    <Container>
      <Title>
        <h2>Varas</h2>
        <div>
            <Button type="primary" onClick={() => {
              setId("")
              setName("")
              setJudge("")
              setIsOpen(true)
            }}>
            <MdAdd size="20" color="#fff" />
            <span>Nova Vara</span>
          </Button>
        </div>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <MdBalance color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={varasTotal} />
            <p>Total</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {varas.length ? (
            <>
              <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td>Nome</td>
                      <td>Juiz</td>
                      <td style={{ width: 80 }}>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      varas.map(vara => (
                        <tr key={vara.id}>
                          <td>{vara.name}</td>
                          <td>{vara.judge}</td>
                          <td style={{width: 120}}>
                            <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                              <MdEdit
                                onClick={() => {
                                  setId(vara.id)
                                  setName(vara.name)
                                  setJudge(vara.judge)
                                  setIsOpen(true)
                                }}
                                style={{ cursor: "pointer", marginLeft: 10 }}
                                size={22}
                                color={"#001B22"}
                              />
                              <MdDelete
                                onClick={() => {
                                  confirm({
                                    title: 'Deseja excluir o Vara?',
                                    icon: <ExclamationCircleFilled/>,
                                    content: `Após essa ação, o Vara ${vara.name} será excluido.`,
                                    onOk() {
                                      deleteVara(vara);
                                    },
                                    onCancel() {},
                                      cancelText: "Cancelar",
                                  });
                                }}
                                style={{ cursor: "pointer", marginLeft: 6 }}
                                size={22}
                                color={"#001B22"}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Scroll>
              <center style={{marginTop: 15}}>
                <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                    setPage(page - 1)
                  }} 
                  total={varasTotal} pageSize={30} 
                  showTotal={(total) => `${total} varas`}
                />
              </center>
            </>
          ) : (
            <div className="error">
              <p>Nenhuma vara encontrado</p>
            </div>
          )}
        </>
      )}

      {isLoading && <Loader />}
      
      <Modal title={id ? "Editar Vara" : "Nova Vara"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={id ? "EDITAR VARA" : "CADASTRAR VARA"} cancelText="FECHAR" onOk={() => { 
        if (id) {
          editVara()
        } else {
          createVara()
        }  
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
          <ViewInput>
            <p>Nome da Vara*</p>
            <Input
              placeholder="nome"
              value={name}
              onChange={(event) => setName(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Juiz*</p>
            <Input
              placeholder="juiz"
              value={judge}
              onChange={(event) => setJudge(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
    </Container>
  );
};

export default Varas;
