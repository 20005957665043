import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Header from '../components/Header';
import Menu from '../components/Menu';
const { Sider } = Layout;

const PrivateRoute = () => {
  const user = localStorage.getItem('@dangersjorge-dash:user');
 
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user]);


  return user ?
    (
      <Layout>
        <Header />
        <Layout>
        <Sider 
          style={{background: "#1C1E4A", minHeight: "calc(100vh - 70px"}} width={230}
          trigger={null} collapsedWidth={0} breakpoint={"xl"}
        >
          <Menu />
        </Sider>
        <Content className='content'>
          <Outlet />
        </Content>
        </Layout>
      </Layout>
    ) : (
      <>
      </>
    );
};

export default PrivateRoute;
