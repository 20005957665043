import React, { useEffect, useState } from 'react';
import {
 MdAdd, MdDelete, MdEdit, MdGroups, MdVisibility
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { ExclamationCircleFilled } from '@ant-design/icons';

import api from '../../services/api';
import { Container, Cards, Card, Title, ViewInput, Table, Scroll, InputMask} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination, Select } from 'antd';

const { confirm } = Modal;

const { TextArea } = Input;

const Clientes = () => {

  const navigate = useNavigate()

  const [clients, setClients] = useState([]);
  const [clientsTotal, setClientsTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [cpfOrCnpj, setCpfOrCnpj] = useState("");
  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [observation, setObservation] = useState("")
  const [email, setEmail] = useState("")
  const [page, setPage] = useState(0)
  const [type, setType] = useState(null)
  const [id, setId] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  useEffect(() => {
    loadClients()
  }, [page]);

  async function loadClients() {
   await api.get(`/clients?page=${page}`).then((response) => {
     setClients(response.data.clients)
     setClientsTotal(response.data.clientsTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function createClient() {
    setIsLoadingModal(true)
    if (!name || !type ){
      toast.warning("Nome e tipo são obrigatórios");
    } else {
      await api.post(`/client`, {
        name: name,
        type: type,
        phoneNumber: phoneNumber,
        email: email, 
        cpfOrCnpj: cpfOrCnpj,
        observation: observation
      }).then((response) => {
        toast.success("Cliente criado com sucesso")
        setIsOpen(false)
        loadClients()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editClient() {
    setIsLoadingModal(true)
    if (!name || !type){
      toast.warning("Nome e tipo são obrigatórios");
    } else {
      await api.put(`/client/${id}`, {
        name: name,
        type: type,
        phoneNumber: phoneNumber,
        email: email, 
        cpfOrCnpj: cpfOrCnpj,
        observation: observation
      }).then((response) => {
        toast.success("Cliente editado com sucesso")
        setIsOpen(false)
        loadClients()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteClient(client) {
    await api.delete(`/client/${client.id}`).then(response => {
      toast.success("Cliente deletado com sucesso")
      loadClients()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  return (
    <Container>
      <Title>
        <h2>Clientes</h2>
        <div>
            <Button type="primary" onClick={() => {
              setId("")
              setName("")
              setEmail("")
              setType(null)
              setCpfOrCnpj("")
              setPhoneNumber("")
              setObservation("")
              setIsOpen(true)
            }}>
            <MdAdd size="20" color="#fff" />
            <span>Novo Cliente</span>
          </Button>
        </div>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <MdGroups color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={clientsTotal} />
            <p>Total</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {clients.length ? (
            <>
              <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td>Nome</td>
                      <td>Email</td>
                      <td style={{minWidth: 100}}>CPF/CNPJ</td>
                      <td style={{minWidth: 100}}>Telefone</td>
                      <td style={{minWidth: 100}}>Processos</td>
                      <td style={{ width: 80 }}>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      clients.map(client => (
                        <tr key={client.id}>
                          <td>{client.name}</td>
                          <td>{client.email || "Não cadastrado"}</td>
                          <td>{client.cpfOrCnpj ? client.type == "pf" ? client.cpfOrCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : client.cpfOrCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : "Não cadastrado"}</td>
                          <td>{client.phoneNumber ? client.phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : "Não cadastrado"}</td>
                          <td>{client.processes.length ? client.processes.length+" processos": "Nenhum processo"} </td>
                          <td style={{width: 120}}>
                            <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                              <MdVisibility
                                onClick={() => { 
                                  navigate(`/cliente/${client.id}`)
                                }}
                                style={{ cursor: "pointer"}}
                                size={22}
                                color={"#001B22"}
                              />
                              <MdEdit
                                onClick={() => {
                                  setId(client.id)
                                  setName(client.name)
                                  setPhoneNumber(client.phoneNumber)
                                  setObservation(client.observation)
                                  setEmail(client.email)
                                  setType(client.type)
                                  setCpfOrCnpj(client.cpfOrCnpj)
                                  setIsOpen(true)
                                }}
                                style={{ cursor: "pointer", marginLeft: 10 }}
                                size={22}
                                color={"#001B22"}
                              />
                              <MdDelete
                                onClick={() => {
                                  confirm({
                                    title: 'Deseja excluir o Cliente?',
                                    icon: <ExclamationCircleFilled/>,
                                    content: `Após essa ação, o Cliente ${client.name} será excluido.`,
                                    onOk() {
                                      deleteClient(client);
                                    },
                                    onCancel() {},
                                      cancelText: "Cancelar",
                                  });
                                }}
                                  style={{ cursor: "pointer", marginLeft: 6 }}
                                  size={22}
                                  color={"#001B22"}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                </Table>
              </Scroll>
              <center style={{marginTop: 15}}>
                <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                    setPage(page - 1)
                 }} 
                total={clientsTotal} pageSize={30} 
                showTotal={(total) => `${total} clientes`}/>
              </center>
            </>
          ) : (
            <div className="error">
              <p>Nenhum cliente encontrado</p>
            </div>
          )}
        </>
      )}

      {isLoading && <Loader />}
      <Modal title={id ? "Editar Cliente" : "Novo Cliente"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={id ? "EDITAR CLIENTE" : "CADASTRAR CLIENTE"} cancelText="FECHAR" onOk={() => { 
        if (id) {
          editClient()
        } else {
          createClient()
        }  
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
          <ViewInput>
            <p>Nome*</p>
            <Input
              placeholder="nome"
              value={name}
              onChange={(event) => setName(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Tipo*</p>
            <Select
              placeholder="tipo"
              value={type} 
              style={{width: "100%", textAlign: "left"}}
              onChange={(value) => { 
                setType(value)
                setCpfOrCnpj("")
              }}>
                <Select.Option value="pf">Pessoa Fisica</Select.Option>
                <Select.Option value="pj">Pessoa Juridica</Select.Option>
              </Select>
          </ViewInput>
          <ViewInput>
            <p>Email</p>
            <Input
              placeholder="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Telefone</p>
            <InputMask
              mask="(99) 99999-9999" maskChar="" noSpaceBetweenChars={true}
              placeholder="telefone"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value.replace(/[^0-9]/g, ''))} />
          </ViewInput>
          {
            type && (
              <ViewInput>
                <p>CPF ou CNPJ</p>
                <InputMask
                  mask={type == "pf" ? "999.999.999-99" : "99.999.999/9999-99"} maskChar="" noSpaceBetweenChars={true}
                  placeholder="cpf ou cnpj"
                  value={cpfOrCnpj}
                  onChange={(event) => setCpfOrCnpj(event.target.value.replace(/[^0-9]/g, ''))} />
              </ViewInput>
            )
          }
          
          <ViewInput>
            <p>Observação</p>
            <TextArea
              placeholder="deixe uma observação"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={observation}
              onChange={(event) => setObservation(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
    </Container>
  );
};

export default Clientes;
