import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Router from './routers';
import { ConfigProvider } from 'antd';
import { AuthProvider } from './hooks/AuthContext';
import api from './services/api';

function App() {

  useEffect(()=>{
    const token = localStorage.getItem('@dangersjorge-dash:token');
    if(token){
      loadUser()
    }
  },[])

  async function loadUser(){
    await api.get("/user").then((response)=>{
      localStorage.setItem('@dangersjorge-dash:user', JSON.stringify(response.data));
    }).catch(({response}) => {
      console.log(response)
      if (response) {
        if (response.data) {
          if (response.data.message == "Usuário não existe") {
            localStorage.removeItem('@dangersjorge-dash:user');
            localStorage.removeItem('@dangersjorge-dash:token');
            toast.warn("Seu usuário foi deletado do sistema")
          }
        }
      } 
    })
  }
  return (
    <ConfigProvider
      theme={{
        token: {
        // Seed Token
        colorPrimary: '#1C1E4A',
        colorPrimaryHover: "#333",
        borderRadius: 2,
        colorFill: '#1C1E4A',
        colorIcon: '#1C1E4A',
        colorBgContainer: '#fff',
      },
      components: {
        Button: {
          colorPrimary: '#1C1E4A',
          colorPrimaryHover: "#1C1E4ACC",
          boxShadow: 0
        },
        Input: {
          lineWidthFocus: 1,
        }
      },
      }}>
      <AuthProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
