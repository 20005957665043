import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Table, Title, Scroll } from './../../GlobalStyles';
import Loader from '../../components/Loader';
import api from '../../services/api';
import { MdDelete, MdVisibility } from 'react-icons/md';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Modal } from 'antd';

const { confirm } = Modal;

const Cliente = () => {

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [client, setClient] = useState({});

  async function loadClient() {
    await api.get(`/client/${id}`).then((response) => {
      setClient(response.data)
      setIsLoading(false)
    })
  }

  async function deleteProcess(process) {
    await api.delete(`/process/${process.id}`).then(response => {
      toast.success("Processo deletado com sucesso")
      loadClient()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  useEffect(() => {
    loadClient()
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Title>
            <h2>Dados do Usuário</h2>
          </Title>
          <p><strong>Nome: </strong>{client.name}</p>
          <p><strong>Email: </strong>{client.email || "Não cadastrado"}</p>
          <p><strong>{client.type == "pf" ? "CPF" : "CNPJ"}: </strong>{client.cpfOrCnpj ? client.type == "pf" ? client.cpfOrCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : client.cpfOrCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : "Não cadastrado"}</p>
          <p><strong>Telefone: </strong>{client.phoneNumber ? client.phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : "Não cadastrado"}</p>
          <p><strong>Observação: </strong>{client.observation || "Nenhuma observação"}</p>
          <Title style={{marginTop: 25, marginBottom: -5}}>
            <h2>{client.processes.length} <span style={{fontWeight: "400"}}>Processos</span></h2>
          </Title>
          

      {!isLoading && (
        <>
          {client.processes.length ? (
            <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td>Número</td>
                      <td>Cliente</td>
                      <td>Advogado</td>
                      <td style={{ width: 80 }}>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      client.processes.map(process => (
                        <tr key={process.id}>
                          <td>{process.number}</td>
                          <td>{process.client.name}</td>
                          <td>{process.lawyer.name}</td>
                          <td style={{width: 120}}>
                            <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                              <MdVisibility
                                onClick={() => { 
                                  navigate(`/processo/${process.id}`)
                                }}
                                style={{ cursor: "pointer"}}
                                size={22}
                                color={"#001B22"}
                              />
                              <MdDelete
                                onClick={() => {
                                  confirm({
                                    title: 'Deseja excluir o Processo?',
                                    icon: <ExclamationCircleFilled/>,
                                    content: `Após essa ação, o Processo ${process.number} será excluido.`,
                                    onOk() {
                                      deleteProcess(process);
                                    },
                                    onCancel() {},
                                      cancelText: "Cancelar",
                                  });
                                }}
                                style={{ cursor: "pointer", marginLeft: 6 }}
                                size={22}
                                color={"#001B22"}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Scroll>
            ) : (
              <div className="error">
                <p>Nenhum processo deste cliente</p>
              </div>
            )
          }
        </>
      )}
        </>
      )}
    </Container>
  );
};

export default Cliente;
