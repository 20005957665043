import styled, { css } from 'styled-components';
import ReactInputMask from 'react-input-mask';
import { Pagination as PaginationAnt } from 'antd';
import IntlCurrencyInput from 'react-intl-currency-input';

export const Container = styled.div`
  height: 100%;
  margin-top: 20px;       
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 80px;

  h2 {
    color: #001B22;
    font-weight: 400;
    margin: 0;
    line-height: 20px;
  }

  .error {
    width: 100%;
    color: #001B22;
    text-align: center;
    margin: 30px 0 50px 0;
    background: #FFF;
    padding: 20px 15px; 
    border-radius: 10px;
  }

  ::-webkit-scrollbar { 
    display: none;
  }
`;


export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  min-height: 32px;

  h2 {
    color: #001B22;
    font-weight: 600;
  }

  div{
    display: flex;
  }

  button {
    margin-left: 25px;
    border: none;
    padding: 10px 15px 10px 10px;
    border-radius: 5px;
    position: relative;
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    transition: all 0.15s ease;
    display: flex;
    align-items: center;
    font-weight: 600;
    span {
      margin-left: 8px;
    }
  }
`;

export const Cards = styled.ul`
  margin: 20px 0 30px 0 !important;
  padding: 0px;
  display: flex;
  align-items: center;

`;

export const Card = styled.li`
  border-radius: 8px;
  padding: 15px;
  min-width: 250px;
  height: 100px;
  background: #fff;
  display: flex;
  color: #333;
  align-items: center;
  position: relative;

  ${({ active }) =>
    active &&
    css`
      background: #1C1E4A;
      color: #fff;
    `}

  .icon {
    padding: 10px;
    border-radius: 5px;
    margin-right: 20px;
  }

  .number {
    span {
      display: block;
      font-size: 24px;
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
    }
  }

  .growth {
    position: absolute;
    top: 10px;
    right: 15px;
    color: green;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const Pagination = styled(PaginationAnt)`
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
`;

export const ViewInput = styled.div`
  min-height: 40px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 5px;
  justify-content: space-between;
  text-align: center;

  p{
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin: 0 0 2px 5px;
  }
`;

export const InputMask = styled(ReactInputMask)`
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 2px;
    
    transition: all 0.2s;
    ::placeholder{
      color: #bbb;
      font-size: 13px;
    }
    &:hover {
    border-color: #001B22;
    } 
    &:focus {
      border-color: #001B22 !important;
      outline-color : #8e9094 !important;
      outline-width: 2px !important;
      outline-style: solid;
    }
`;

export const InputValue = styled(IntlCurrencyInput)`
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 2px;

    transition: all 0.2s;
    ::placeholder{
      color: #bbb;
      font-size: 13px;
    }
    &:hover {
     border-color: #001B22;
    } 
    &:focus {
      border-color: #001B22 !important;
      outline-color : #8e9094 !important;
      outline-width: 2px !important;
      outline-style: solid;
    }
`;

export const Scroll = styled.div`
  width: 100%;
  overflow-x: auto;
`
export const Table = styled.table`

  width: 100%;
  min-width: 500px;
  margin-top: 25px;
  border: none;
  border-collapse: separate;
  color: #000;
  border-spacing: 0 1em;

  thead {
    tr {
      td {
        padding: 0 15px;
        font-weight: 600;
        &:last-child {
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {

      height: auto;

      td {
        color: #000;
        font-size: 13px;
        min-height: 40px;
        height: 100%;
        padding: 8px 15px;
        background: #fff;

        a {
          margin: 10px;
        }

        &:first-child {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }

        &:last-child {
          text-align: center;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
    }
  }
`;
