import React, { useEffect, useState } from 'react';
import {
 MdAdd, MdDelete, MdEdit, MdCalendarMonth,
 MdChangeCircle,
 MdRefresh
} from 'react-icons/md';
import CountUp from 'react-countup';
import { ExclamationCircleFilled } from '@ant-design/icons';

import api from '../../services/api';
import { Container, Cards, Card, Title, ViewInput, Table, Scroll} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination, Select, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import dayjs from 'dayjs';

import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { addDays, format } from 'date-fns';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

dayjs.extend(weekday)
dayjs.extend(localeData)

const { confirm } = Modal;

const Agenda = () => {

  const [tasks, setTasks] = useState([]);
  const [tasksTotal, setTasksTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [lawyersAll, setLawyersAll] = useState([]);
  const [clientsAll, setClientsAll] = useState([]);
  const [processesClient, setProcessesClient] = useState([]);
  const [description, setDescription] = useState("")
  const [date, setDate] = useState("")
  const [process, setProcess] = useState("")
  const [client, setClient] = useState("")
  const [type, setType] = useState("")
  const [lawyer, setLawyer] = useState("")
  const [title, setTitle] = useState("")
  const [page, setPage] = useState(0)
  const [dateStart, setDateStart] = useState(new Date())
  const [dateEnd, setDateEnd] = useState(addDays(new Date(), 30))
  const [id, setId] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  useEffect(() => {
    loadTasks()
  }, [page, dateStart, dateEnd ,lawyer]);

  useEffect(() => {
    loadLawyer()
    loadClients()
  }, []);

  async function loadClients() {
    await api.get(`/clients?all=true`).then((response) => {
      setClientsAll(response.data.clients)
     }).catch(({ response }) => {
       if (response) {
         if (response.data) {
           if (response.data.message) {
             toast.warn(response.data.message)
           } else {
             toast.error("Erro Interno. verifique sua conexão e tente novamente")
           }
         } else {
           toast.error("Erro Interno. verifique sua conexão e tente novamente")
         }
       } else {
         toast.error("Erro Interno. verifique sua conexão e tente novamente")
       }
     })
  }

  async function loadTasks() {
   await api.get(`/tasks?page=${page}&dateStart=${dateStart}&dateEnd=${dateEnd}`).then((response) => {
     setTasks(response.data.tasks)
     setTasksTotal(response.data.tasksTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function loadLawyer() {
    await api.get(`/users?all=true&type=LAWYER`).then((response) => {
      let lawyers = response.data.users
      setLawyersAll(lawyers)
     }).catch(({ response }) => {
       if (response) {
         if (response.data) {
           if (response.data.message) {
             toast.warn(response.data.message)
           } else {
             toast.error("Erro Interno. verifique sua conexão e tente novamente")
           }
         } else {
           toast.error("Erro Interno. verifique sua conexão e tente novamente")
         }
       } else {
         toast.error("Erro Interno. verifique sua conexão e tente novamente")
       }
     })
  }

  async function createTask() {
    setIsLoadingModal(true)
    if (!title || !date || !type){
      toast.warning("Titulo, tipo e data são obrigatórios");
    } else {
      await api.post(`/task`, { 
        title: title, 
        description: description, 
        date: date, 
        clientId: client,
        processId: process, 
        type: type,
        createSecretary: true,
        userId: lawyer
      }).then((response) => {
        toast.success("Tarefa criada com sucesso")
        setIsOpen(false)
        loadTasks()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editTask() {
    setIsLoadingModal(true)
    if (!title || !date || !type){
      toast.warning("Titulo, tipo e data são obrigatórios");
    } else {
      await api.put(`/task/${id}`, { 
        title: title, 
        description: description, 
        date: date, 
        clientId: client,
        processId: process, 
        type: type,
        createSecretary: true,
        userId: lawyer
      }).then((response) => {
        toast.success("Tarefa editada com sucesso")
        setIsOpen(false)
        loadTasks()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteTask(task) {
    await api.delete(`/task/${task.id}?createSecretary=true`).then(response => {
      toast.success("Tarefa deletada com sucesso")
      loadTasks()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  function getDate(date) {
    let fortmatDate = date.split("/")
    return new Date(fortmatDate[2], fortmatDate[1] - 1, fortmatDate[0])
  }

  return (
    <Container>
            <Title>
              <h2>Agenda</h2>
              <div>
                <Button 
                  type="primary" 
                  onClick={() => {
                    setIsLoading(true)
                    loadTasks()
                  }}
                >
                  <MdRefresh size="20" color="#fff" />
                </Button>
                <Button 
                  type="primary" 
                  onClick={() => {
                    setId("")
                    setTitle("")
                    setDescription("")
                    setLawyer("")
                    setType(null)
                    setClient(null)
                    setProcess(null)
                    setProcessesClient([])
                    setDate("")
                    setIsOpen(true)
                  }}
                >
                  <MdAdd size="20" color="#fff" />
                  <span>Nova Tarefa</span>
                </Button>
              </div>
            </Title>
            <Cards>
              <Card>
                <div className="icon">
                  <MdCalendarMonth color="#848484" size={32} />
                </div>
                <div className="number">
                  <CountUp duration={1} end={tasksTotal} />
                  <p>Total no periodo</p>
                </div>
              </Card>
            </Cards>

            {!isLoading && (
              <>
                {tasks.length ? (
                  <>
                    <ViewInput style={{width: "100%", maxWidth: 280}}>
                      <p>Alterar Periodo</p>
                      <RangePicker
                        placeholder={["data inicial", "data final"]}
                        value={[dateStart ? dayjs(dateStart) : "", dateEnd ? dayjs(dateEnd) : ""]}
                        format={"DD/MM/YYYY"}
                        onChange={(value, dateString)=>{
                          setDateStart(getDate(dateString[0]))
                          setDateEnd(getDate(dateString[1]))
                          setPage(0)
                        }}
                        allowClear={false}
                      />
                    </ViewInput>
                    <Scroll>
                      <Table style={{marginTop: 5}}>
                        <thead>
                          <tr>
                            <td>Tipo</td>
                            <td>Titulo</td>
                            <td>Advogado</td>
                            <td>Data</td>
                            <td style={{ width: 80 }}>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            tasks.map(task => (
                              <tr key={task.id}>
                                <td>{task.type}</td>
                                <td>{task.title}</td>
                                <td>{task.userId ? lawyersAll.find((item)=> item.id == task.userId)?.name : "Todos"}</td>
                                <td>{format(task.date, "dd/MM/yyyy HH:mm")}</td>
                                <td style={{width: 120}}>
                                  <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                                    <MdEdit
                                      onClick={() => {
                                        setId(task.id)
                                        setTitle(task.title)
                                        setLawyer(task.userId)
                                        setDescription(task.description)
                                        setType(task.type)
                                        setClient(task.clientId)
                                        setProcess(task.processId)
                                        setProcessesClient(clientsAll.find((item)=> item.id == task.clientId)?.processes || [])
                                        setDate(task.date)
                                        setIsOpen(true)
                                      }}
                                      style={{ cursor: "pointer", marginLeft: 10 }}
                                      size={22}
                                      color={"#001B22"}
                                    />
                                    <MdDelete
                                      onClick={() => {
                                        confirm({
                                          title: 'Deseja excluir a Tarefa?',
                                          icon: <ExclamationCircleFilled/>,
                                          content: `Após essa ação, a Tarefa ${task.name} será excluida.`,
                                          onOk() {
                                            deleteTask(task);
                                          },
                                          onCancel() {},
                                            cancelText: "Cancelar",
                                        });
                                      }}
                                      style={{ cursor: "pointer", marginLeft: 6 }}
                                      size={22}
                                      color={"#001B22"}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </Scroll>
                    <center style={{marginTop: 15}}>
                      <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                          setPage(page - 1)
                        }} 
                        total={tasksTotal} pageSize={30} 
                        showTotal={(total) => `${total} tarefas`}
                      />
                    </center>
                  </>
                ) : (
                  <div className="error">
                    <p>Nenhuma tarefa encontrada</p>
                  </div>
                )}
              </>
            )}

            {isLoading && <Loader />}
            
            <Modal title={id ? "Editar Tarefa" : "Nova Tarefa"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={id ? "EDITAR TAREFA" : "CADASTRAR TAREFA"} cancelText="FECHAR" onOk={() => { 
              if (id) {
                editTask()
              } else {
                createTask()
              }  
            }} onCancel={() => {
              setIsOpen(false)
            }}>
              <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
                <ViewInput>
                  <p>Titulo da Tarefa*</p>
                  <Input
                    placeholder="titulo"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)} />
                </ViewInput>
                <ViewInput>
                  <p>Descrição da Tarefa</p>
                  <TextArea
                    placeholder="descreva a tarefa"
                    value={description}
                    autoSize={{ maxRows: 5}}
                    onChange={(event) => setDescription(event.target.value)} />
                </ViewInput>
                <ViewInput style={{width: "100%", maxWidth: 450}}>
                  <p>Selecione o Advogado*</p>
                  <Select
                    placeholder="selecionar advogado"
                    value={lawyer} 
                    showSearch
                    optionFilterProp="children"
                    style={{width: "100%", textAlign: "left"}}
                    onChange={(value) => { 
                      setLawyer(value)
                    }}>
                      <Select.Option value={""}>Todos advogados</Select.Option>
                      {
                        lawyersAll.map((item)=>{
                          return(
                            <Select.Option value={item.id}>{item.name}</Select.Option>
                          )
                        })
                      }
                  </Select>
                </ViewInput>
                <ViewInput>
                  <p>Tipo*</p>
                    <Select
                      placeholder="selecionar tipo"
                      value={type} 
                      style={{width: "100%", textAlign: "left"}}
                      onChange={(value) => { 
                        setType(value)
                      }}>
                        <Select.Option value={"Prazo"}>Prazo</Select.Option>
                        <Select.Option value={"Compromisso"}>Compromisso</Select.Option>
                    </Select>
                </ViewInput>
                <ViewInput>
                  <p>Selecione o Cliente</p>
                  <Select
                    placeholder="selecionar cliente"
                    value={client} 
                    showSearch
                    optionFilterProp="children"
                    style={{width: "100%", textAlign: "left"}}
                    onChange={(value) => { 
                      setClient(value)
                      setProcess(null)
                      setProcessesClient(clientsAll.find((item)=> item.id == value)?.processes || [])
                    }}>
                      {
                        clientsAll.map((item)=>{
                          return(
                            <Select.Option value={item.id}>{item.name}</Select.Option>
                          )
                        })
                      }
                  </Select>
                </ViewInput>
                {!!client && (
                  <ViewInput>
                    <p>Selecione o Processo</p>
                    <Select
                      placeholder="selecionar processo"
                      value={process} 
                      showSearch
                      optionFilterProp="children"
                      style={{width: "100%", textAlign: "left"}}
                      onChange={(value) => { 
                        setProcess(value)
                      }}>
                        {
                          processesClient.map((item)=>{
                            return(
                              <Select.Option value={item.id}>Processo: {item.number}</Select.Option>
                            )
                          })
                        }
                    </Select>
                  </ViewInput>
                )}
                <ViewInput>
                  <p>Data e Horário*</p>
                  <DatePicker
                    placeholder={"selecionar data e horario"}
                    showTime
                    showSecond={false}
                    showHour={true}
                    showMinute={true}
                    format={"DD/MM/YYYY HH:mm"}
                    locale={locale}
                    minDate={dayjs(new Date())}
                    value={date ? dayjs(date) : ""}
                    style={{
                      width: "100%"
                    }}
                    onChange={(date, d) => setDate(date)}
                  />
                </ViewInput>
              </div>
            </Modal>
    </Container>
  );
};

export default Agenda;
