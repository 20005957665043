import React, { useEffect, useState } from 'react';
import {
 MdAdd, MdDelete, MdEdit, MdVisibility,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { ExclamationCircleFilled } from '@ant-design/icons';
import api from '../../services/api';
import { Container, Cards, Card, Title, ViewInput, Table, Scroll, InputValue } from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination, Select } from 'antd';
import { FaFileAlt } from "react-icons/fa";

const { confirm } = Modal;

const Processos = () => {

  const navigate = useNavigate()

  const [processes, setProcesses] = useState([]);
  const [clients, setClients] = useState([]);
  const [varas, setVaras] = useState([]);
  const [lawyersAll, setLawyersAll] = useState([]);
  const [processesTotal, setProcessesTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [lawyers, setLawyers] = useState([]);
  const [reu, setReu] = useState("")
  const [status, setStatus] = useState(null)
  const [client, setClient] = useState(null)
  const [poloActive, setPoloActive] = useState(true)
  const [number, setNumber] = useState("")
  const [action, setAction] = useState("")
  const [vara, setVara] = useState(null)
  const [value, setValue] = useState(0)
  const [link, setLink] = useState("")
  const [lawyerAuthor, setLawyerAuthor] = useState(null)
  const [page, setPage] = useState(0)
  const [id, setId] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  useEffect(() => {
    loadProcesses()
  }, [page]);
  
  useEffect(() => {
    loadClients()
    loadVaras()
    loadLawyer()
  }, []);

  async function loadProcesses() {
    await api.get(`/processes?page=${page}`).then((response) => {
      setProcesses(response.data.processes)
      setProcessesTotal(response.data.processesTotal)
     }).catch(({ response }) => {
       if (response) {
         if (response.data) {
           if (response.data.message) {
             toast.warn(response.data.message)
           } else {
             toast.error("Erro Interno. verifique sua conexão e tente novamente")
           }
         } else {
           toast.error("Erro Interno. verifique sua conexão e tente novamente")
         }
       } else {
         toast.error("Erro Interno. verifique sua conexão e tente novamente")
       }
     })
    setIsLoading(false);
  }

  async function loadLawyer() {
    await api.get(`/users?all=true&type=LAWYER`).then((response) => {
      let lawyers = response.data.users
      setLawyersAll(lawyers)
     }).catch(({ response }) => {
       if (response) {
         if (response.data) {
           if (response.data.message) {
             toast.warn(response.data.message)
           } else {
             toast.error("Erro Interno. verifique sua conexão e tente novamente")
           }
         } else {
           toast.error("Erro Interno. verifique sua conexão e tente novamente")
         }
       } else {
         toast.error("Erro Interno. verifique sua conexão e tente novamente")
       }
     })
  }

  async function loadClients() {
    await api.get(`/clients?all=true`).then((response) => {
      setClients(response.data.clients)
     }).catch(({ response }) => {
       if (response) {
         if (response.data) {
           if (response.data.message) {
             toast.warn(response.data.message)
           } else {
             toast.error("Erro Interno. verifique sua conexão e tente novamente")
           }
         } else {
           toast.error("Erro Interno. verifique sua conexão e tente novamente")
         }
       } else {
         toast.error("Erro Interno. verifique sua conexão e tente novamente")
       }
     })
  }

  async function loadVaras() {
   await api.get(`/varas?all=true`).then((response) => {
     setVaras(response.data.varas)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  async function createProcess() {
    setIsLoadingModal(true)
    if (!number || !client || !status || !reu || !vara || !action || !lawyerAuthor){
      toast.warning("Preencha todos os campos obrigatórios");
    } else {
      await api.post(`/process`, {
        number: number,
        clientId: client,
        status: status,
        reu: reu, 
        varaId: vara,
        lawyerAuthor: lawyerAuthor,
        lawyers: lawyers ? String(lawyers) : "",
        action: action,
        value: value, 
        link: link
      }).then((response) => {
        toast.success("Processo cadastrado com sucesso")
        setIsOpen(false)
        loadProcesses()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editProcess() {
    setIsLoadingModal(true)
    if (!number || !client || !status || !reu || !vara || !action || !lawyerAuthor){
      toast.warning("Preencha todos os campos obrigatórios");
    } else {
      await api.put(`/process/${id}`, {
        number: number,
        clientId: client,
        status: status,
        reu: reu, 
        varaId: vara,
        lawyerAuthor: lawyerAuthor,
        lawyers: lawyers ? String(lawyers) : "",
        action: action,
        value: value, 
        link: link
      }).then((response) => {
        toast.success("Processo editado com sucesso")
        setIsOpen(false)
        loadProcesses()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteProcess(process) {
    await api.delete(`/process/${process.id}`).then(response => {
      toast.success("Processo deletado com sucesso")
      loadProcesses()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  return (
    <Container>
      <Title>
        <h2>Processos</h2>
        <div>
            <Button type="primary" onClick={() => {
              setId("")
              setNumber("")
              setClient(null)
              setStatus(null)
              setReu("")
              setLawyerAuthor(null)
              setLawyers([])
              setAction("")
              setVara(null)
              setValue(0)
              setLink("")
              setIsOpen(true)
            }}>
            <MdAdd size="20" color="#fff" />
            <span>Novo Processo</span>
          </Button>
        </div>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <FaFileAlt color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={processesTotal} />
            <p>Total</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {processes.length ? (
            <>
              <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td>Número</td>
                      <td>Status</td>
                      <td>Cliente</td>
                      <td>Advogado</td>
                      <td style={{ width: 80 }}>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      processes.map(process => (
                        <tr key={process.id}>
                          <td>{process.number}</td>
                          <td style={{textTransform: "capitalize"}}>{process.status}</td>
                          <td>{process.client.name}</td>
                          <td>{process.lawyer.name}</td>
                          <td style={{width: 120}}>
                            <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                              <MdVisibility
                                onClick={() => { 
                                  navigate(`/processo/${process.id}`)
                                }}
                                style={{ cursor: "pointer"}}
                                size={22}
                                color={"#001B22"}
                              />
                              <MdEdit
                                onClick={() => {
                                  setId(process.id)
                                  setNumber(process.number)
                                  setClient(process.clientId)
                                  setStatus(process.status)
                                  setReu(process.reu)
                                  setLawyerAuthor(process.lawyerAuthor)
                                  setLawyers(process.lawyers ? process.lawyers.split(",") : [])
                                  setAction(process.action)
                                  setVara(process.varaId)
                                  setValue(process.value)
                                  setLink(process.link)
                                  setIsOpen(true)
                                }}
                                style={{ cursor: "pointer", marginLeft: 10 }}
                                size={22}
                                color={"#001B22"}
                              />
                              <MdDelete
                                onClick={() => {
                                  confirm({
                                    title: 'Deseja excluir o Processo?',
                                    icon: <ExclamationCircleFilled/>,
                                    content: `Após essa ação, o Processo ${process.number} será excluido.`,
                                    onOk() {
                                      deleteProcess(process);
                                    },
                                    onCancel() {},
                                      cancelText: "Cancelar",
                                  });
                                }}
                                style={{ cursor: "pointer", marginLeft: 6 }}
                                size={22}
                                color={"#001B22"}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Scroll>
              <center style={{marginTop: 15}}>
                <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                    setPage(page - 1)
                  }} 
                  total={processesTotal} pageSize={30} 
                  showTotal={(total) => `${total} processos`}
                />
              </center>
            </>
          ) : (
            <div className="error">
              <p>Nenhum processo encontrado</p>
            </div>
          )}
        </>
      )}

      {isLoading && <Loader />}
      <Modal title={id ? "Editar Processo" : "Novo Processo"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={id ? "EDITAR PROCESSO" : "CADASTRAR PROCESSO"} cancelText="FECHAR" onOk={() => { 
        if (id) {
          editProcess()
        } else {
          createProcess()
        }  
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
          <ViewInput>
            <p>Número do Processo*</p>
            <Input
              placeholder="número do processo"
              value={number}
              onChange={(event) => setNumber(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Cliente ( {poloActive ? "Polo Ativo" : "Polo Passivo"} )*</p>
            <Select
              placeholder="selecionar cliente"
              showSearch
              optionFilterProp="children"
              value={client} 
              style={{width: "100%", textAlign: "left"}}
              onChange={(value) => { 
                setClient(value)
              }}>
                {
                  clients.map((item)=>{
                    return(
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    )
                  })
                }
              </Select>
          </ViewInput>
          <ViewInput>
            <p>Status*</p>
            <Select
              placeholder="selecionar status"
              value={status} 
              showSearch
              optionFilterProp="children"
              style={{width: "100%", textAlign: "left"}}
              onChange={(value) => { 
                setStatus(value)
              }}>
                <Select.Option value="ativo">Ativo</Select.Option>
                <Select.Option value="inativo">Inativo</Select.Option>
                <Select.Option value="distribuído">Distribuído</Select.Option>
                <Select.Option value="em andamento">Em andamento</Select.Option>
                <Select.Option value="concluído">Concluído</Select.Option>
                <Select.Option value="arquivado">Arquivado</Select.Option>
                <Select.Option value="suspenso">Suspenso</Select.Option>
                <Select.Option value="em grau de recurso">Em grau de recurso</Select.Option>
                <Select.Option value="baixado">Baixado</Select.Option>
                <Select.Option value="extinto">Extinto</Select.Option>
                <Select.Option value="trânsito em julgado">Trânsito em julgado</Select.Option>
                <Select.Option value="sentenciado">Sentenciado</Select.Option>
                <Select.Option value="em execução">Em execução</Select.Option>
                <Select.Option value="julgado">Julgado</Select.Option>
                <Select.Option value="apensado">Apensado</Select.Option>
              </Select>
          </ViewInput>
          <ViewInput>
            <p>Autor do Processo?*</p>
            <Select
              placeholder="selecionar polo"
              value={poloActive} 
              style={{width: "100%", textAlign: "left"}}
              onChange={(value) => { 
                setPoloActive(value)
              }}>
                <Select.Option value={true}>Cliente Polo Ativo</Select.Option>
                <Select.Option value={false}>Cliente Polo Passivo</Select.Option>
              </Select>
          </ViewInput>
          <ViewInput>
            <p>{poloActive ? "Réu" : "Autor"}</p>
            <Input
              placeholder={poloActive ? "réu" : "autor"}
              value={reu}
              onChange={(event) => setReu(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Advogado Autor</p>
            <Select
              placeholder="selecionar advogado autor"
              value={lawyerAuthor} 
              showSearch
              optionFilterProp="children"
              style={{width: "100%", textAlign: "left"}}
              onChange={(value) => { 
                setLawyerAuthor(value)
              }}>
                {
                  lawyersAll.map((item)=>{
                    return(
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    )
                  })
                }
              </Select>
          </ViewInput>
          <ViewInput>
            <p>Advogados</p>
            <Select
              placeholder="outros advogados"
              value={lawyers} 
              mode="multiple"
              optionFilterProp="children"
              style={{width: "100%", textAlign: "left"}}
              onChange={(value) => { 
                setLawyers(value)
              }}>
                {
                  lawyersAll.map((item)=>{
                    return(
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    )
                  })
                }
              </Select>
          </ViewInput>
          <ViewInput>
            <p>Ação*</p>
            <Input
              placeholder="ação"
              value={action}
              onChange={(event) => setAction(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Vara*</p>
            <Select
              placeholder="selecionar vara"
              value={vara} 
              showSearch
              optionFilterProp="children"
              style={{width: "100%", textAlign: "left"}}
              onChange={(value) => { 
                setVara(value)
              }}>
                {
                  varas.map((item)=>{
                    return(
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    )
                  })
                }
              </Select>
          </ViewInput>
          <ViewInput>
            <p>Valor da Ação</p>
            <InputValue
              style={{width: "100%", textAlign: "left"}}
              currency="BRL"
              config={{
                locale: "pt-BR",
                formats: {
                  number: {
                    BRL: {
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  },
                },
              }}
              value={value}
              onChange={(event, value) => { 
                setValue(value)
              }}/>
          </ViewInput>
          <ViewInput>
            <p>Link</p>
            <Input
              placeholder="link"
              value={link}
              onChange={(event) => setLink(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
    </Container>
  );
};

export default Processos;
