import React, { useEffect, useState } from 'react';

import {
  MdAdminPanelSettings, MdGroups, MdBalance,
  MdExitToApp, MdWork, MdSupportAgent,
  MdCalendarMonth
} from "react-icons/md";

import { FaFileAlt } from "react-icons/fa";

import { useLocation, useNavigate } from 'react-router-dom';

import { Menu } from 'antd';
import "./style.css"
import { useAuth } from '../../hooks/AuthContext';

const MenuOptions = () => {

  const navigate = useNavigate();
  const local = useLocation();

  const { signOut, user } = useAuth();

  const [route, setRoute] = useState("")

  useEffect(() => {
    options.map((item, index) => {
      if (item.route == local.pathname) {
       setRoute(`${index}`)
     }
    })
  }, [local])

  const options = [
    {
      name: "Processos",
      route: "/",
      icon: (<FaFileAlt size={20} />)
    },
    {
      name: "Secretárias",
      route: "/secretarias",
      icon: (<MdSupportAgent size={20} />)
    },
    {
      name: "Advogados",
      route: "/advogados",
      icon: (<MdWork size={20} />)
    },
    {
      name: "Administradores",
      route: "/administradores",
      icon: (<MdAdminPanelSettings size={20} />)
    },
    {
      name: "Clientes",
      route: "/clientes",
      icon: (<MdGroups size={20} />)
    },
    {
      name: "Agenda",
      route: "/agenda",
      icon: (<MdCalendarMonth size={20} />)
    },
    {
      name: "Varas",
      route: "/varas",
      icon: (<MdBalance size={20} />)
    },
    {
      name: "Sair",
      route: "/login",
      icon: (<MdExitToApp size={20} />)
    }
  ]
  
  return (
    <Menu mode="inline" className="menu" selectedKeys={[route]}>
      {
        options.map((item, index) => {
          if(user.role == "SECRETARY"){
            if(item.route == "/secretarias" || item.route == "/administradores"){
              return null
            }
          }
          return (
            <Menu.Item key={index} onClick={(changeSelectedKey) => {
              setRoute(changeSelectedKey.key)
              if (item.route == "/login") {
                signOut()
              }
              navigate(item.route)
            }}
              className="option"
              type="primary"
              icon={item.icon}
            >
              {item.name}
            </Menu.Item>
          )
        })
      }
    </Menu>
  );
};

export default MenuOptions;
