import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdMail, MdPassword } from 'react-icons/md';

import logo from "./../../images/logoWhite.png"

import { Container, Content } from './styles';
import { Button, Input } from 'antd';
import { useAuth } from '../../hooks/AuthContext';
import api from '../../services/api';
import { toast } from 'react-toastify';

const Login = () => {

  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();

  async function login() {
    setLoading(true)
    await api.post("/sessions", { email, password }).then((response) => {
      if(response.data.role == "LAWYER"){
        toast.error("Login de advogados apenas no aplicativo")
      }else{
        signIn({ user: response.data })
        navigate("/")
      }
    }).catch(( response ) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
    setLoading(false)
  }

  return (
    <Container>
      <Content>
        <div className='logo'>
          <img src={logo}/>
        </div>
        <div className='input'>
          <p>Digite seu email</p>
            <Input
              style={{ height: 40, fontSize: 16, paddingLeft: 10}}
              type="email"
              placeholder="email"
              icon={<MdMail style={{ marginRight: 10 }} />}
              onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                      login()
                  }
              }}
              onChange={(text) => {
                setEmail(text.target.value.replace(/ /g, ''))
              }}
              value={email}
            />
          </div>  
          <div className='input'>
            <p>Digite sua senha</p>
            <Input
              style={{ height: 40, fontSize: 16, paddingLeft: 10}}
              type="password"
              placeholder="senha"
              onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                      login()
                  }
              }}
              icon={<MdPassword style={{ marginRight: 10 }} />}
              onChange={(text)=>{setPassword(text.target.value)}}
              value={password}
            />
          </div>  
          <Button type="loading" loading={loading}
            onClick={() => {
              login()
            }} 
            style={{width: "calc(100% - 10px)", background: "#1C1E4A", color: "#FFF", borderRadius: 5}}
          >
            Entrar
          </Button>
      </Content>
    </Container>
  );
};

export default Login;
