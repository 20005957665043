import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@dangersjorge-dash:token');
    const user = localStorage.getItem('@dangersjorge-dash:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const update = useCallback(({ user }) => {
      localStorage.setItem('@dangersjorge-dash:user', JSON.stringify(user));
      setData({ token: data.token, user: user });
  }, []);

  const signIn = useCallback(async ({ user }) => {

      localStorage.setItem('@dangersjorge-dash:token', user.token);
      localStorage.setItem('@dangersjorge-dash:user', JSON.stringify(user));

      api.defaults.headers = {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*"
      }
      
      api.defaults.headers.authorization = `Bearer ${user.token}`;

      setData({ token: user.token, user });
      
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@dangersjorge-dash:user');
    localStorage.removeItem('@dangersjorge-dash:token');

    setData({});
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, update }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const contenxt = useContext(AuthContext);
  if (!contenxt) {
    throw new Error('useAuth must be used with an AuthProvider');
  }
  return contenxt;
}

export { AuthProvider, useAuth };
