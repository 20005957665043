import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Title, ViewInput } from './../../GlobalStyles';
import Loader from '../../components/Loader';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Observations } from './styles';
import { MdDelete, MdEdit } from 'react-icons/md';

const { confirm } = Modal;
const { TextArea } = Input;

const Processo = () => {

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [process, setProcess] = useState({});
  const [idObservation, setIdObservation] = useState("")
  const [description, setDescription] = useState("")
  const [fileUrl, setFileUrl] = useState("")
  const [file, setFile] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  async function loadProcess() {
    await api.get(`/process/${id}`).then((response) => {
      setProcess(response.data)
      setIsLoading(false)
    })
  }

  async function createObservation(){
    setIsLoadingModal(true)
    if (!description && !file){
      toast.warning("Preencha a observação ou envie um arquivo");
    } else {
      const data = new FormData();
      data.append('description', description);
      if (file.name) {
        data.append('file', file, file.name);
      }
      await api.post(`/process/observation/${id}`, data).then((response) => {
        toast.success("Observação adicionada com sucesso")
        setIsOpen(false)
        loadProcess()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editObservation(){
    setIsLoadingModal(true)
    if (!description && (!file || !fileUrl)){
      toast.warning("Preencha a observação ou envie um arquivo");
    } else {
      const data = new FormData();
      data.append('description', description);
      if (file.name) {
        data.append('file', file, file.name);
      }
      await api.put(`/process/observation/${idObservation}`, data).then((response) => {
        toast.success("Observação editada com sucesso")
        setIsOpen(false)
        loadProcess()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteObservation(observation){
    await api.delete(`/process/observation/${observation.id}`).then(response => {
      toast.success("Observação deletada com sucesso")
      loadProcess()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  useEffect(() => {
    loadProcess()
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Title>
            <h2>Número do Processo: <span style={{fontWeight: "400"}}>{process.number}</span></h2>
          </Title>
          <p><strong>Polo Ativo: </strong>{process.poloActive ? process.client?.name : process.reu}</p>
          <p><strong>Polo Passivo: </strong>{process.poloActive ? process.reu : process.client?.name}</p>
          <p><strong>Advogado: </strong>{process.lawyer.name}</p>
          <p><strong>Outros Advogados: </strong>{process.lawyersName || "Não cadastrado"}</p>
          <p><strong>Ação: </strong>{process.action || "Não cadastrado"}</p>
          <p><strong>Vara: </strong>{process.vara.name || "Não cadastrado"}</p>
          <p><strong>Juiz: </strong>{process.vara.judge || "Não cadastrado"}</p>
          <p><strong>Valor da Ação: </strong>{process.value ? process.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "Não cadastrado"}</p>
          <p><strong>Link: </strong>{process.link ? <a href={process.link} target="_blank" rel="noopener noreferrer" >{process.link}</a> : "Não cadastrado"}</p>
          <center style={{margin: "25px 0"}}>
            <Button 
              type="primary" 
              onClick={()=>{
                setIsLoadingModal(false)
                setIsOpen(true)
                setDescription("")
                setFile("")
              }}>
               Adicionar Observação
            </Button>
          </center>
          {
            process.observations.length ? (
              <>
                { 
                  process.observations.map((observation)=>{
                    return(
                      <Observations>
                        <div style={{width: "calc(100% - 70px)"}}>
                          <p>{observation.description}</p>
                          <a style={{fontSize: 12}} href={observation.file_url} target="_blank" rel="noopener noreferrer" >{observation.file_url}</a>
                        </div>
                        <div style={{minWidth: 70, maxWidth: 70, flexDirection: "row"}}>
                          <MdEdit
                            onClick={() => {
                              setIdObservation(observation.id)
                              setDescription(observation.description)
                              setFile("")
                              setFileUrl(observation.file_url)
                              setIsOpen(true)
                            }}
                            style={{ cursor: "pointer", marginLeft: 10 }}
                            size={22}
                            color={"#001B22"}
                          />
                          <MdDelete
                            onClick={() => {
                              confirm({
                                title: 'Deseja excluir a Observação?',
                                icon: <ExclamationCircleFilled/>,
                                content: `Após essa ação, a Observação será excluida.`,
                                onOk() {
                                  deleteObservation(observation);
                                },
                                onCancel() {},
                                  cancelText: "Cancelar",
                              });
                            }}
                            style={{ cursor: "pointer", marginLeft: 6 }}
                            size={22}
                            color={"#001B22"}
                          />
                        </div>
                      </Observations>
                    )
                  })
                }
              </>
            ) : (
              <div className="error">
                <p>Nenhuma observação encontrada</p>
              </div>
            )
          }
        </>
      )}
      <Modal title={idObservation ? "Editar Observação" : "Nova Observação"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={idObservation? "EDITAR OBSERVAÇÃO" : "CADASTRAR OBSERVAÇÃO"} cancelText="FECHAR" onOk={() => { 
        if (idObservation) {
          editObservation()
        }else{
          createObservation()
        }
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
          <ViewInput>
            <p>Observação*</p>
            <TextArea
              placeholder="observação"
              value={description}
              onChange={(event) => setDescription(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>{(idObservation && fileUrl) && "Novo "}Arquivo*</p>
            <Input
              type='file'
              onChange={(file) => {
                const fileSend = file.target.files[0]
                setFile(fileSend)
              }} />
          </ViewInput>
          {
            (idObservation && fileUrl) && (
              <ViewInput style={{minHeight: 10, marginBottom: 0}}>
                <p>Arquivo anterior</p>
              </ViewInput>
            )
          }
          
          {(idObservation && fileUrl) && (
            <a style={{textAlign: "left !important", fontSize: 10, width: "100%"}} href={fileUrl} target="_blank" rel="noopener noreferrer" >{fileUrl}</a>
          )}
        </div>
      </Modal>
    </Container>
  );
};

export default Processo;
