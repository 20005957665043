import React, { useEffect, useState } from 'react';
import {
 MdAdd, MdAddPhotoAlternate, MdDelete, MdEdit, MdWork
} from 'react-icons/md';
import userPhoto from "./../../images/user.png"
import CountUp from 'react-countup';
import { ExclamationCircleFilled } from '@ant-design/icons';
import api from '../../services/api';
import { Container, Cards, Card, Title, ViewInput, Table, Scroll} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination } from 'antd';

const { confirm } = Modal;

const { TextArea } = Input;

const Advogados = () => {

  const [users, setUsers] = useState([]);
  const [usersTotal, setUsersTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [oab, setOab] = useState("");
  const [name, setName] = useState("")
  const [observation, setObservation] = useState("")
  const [email, setEmail] = useState("")
  const [page, setPage] = useState(0)
  const [password, setPassword] = useState("")
  const [photo, setPhoto] = useState("")
  const [id, setId] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  useEffect(() => {
    loadUsers()
  }, [page]);

  async function loadUsers() {
   await api.get(`/users?page=${page}&type=LAWYER`).then((response) => {
     setUsers(response.data.users)
     setUsersTotal(response.data.usersTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function createUser() {
    setIsLoadingModal(true)
    if (!name || !email || !password){
      toast.warning("Nome, Email e Senha são obrigatórios");
    } else {
      const data = new FormData();
      data.append('name', name);
      data.append('email', email);
      data.append('oab', oab);
      data.append('password', password);
      data.append('observation', observation);
      data.append('role', "LAWYER");
      if (photo.name) {
        data.append('file', photo, photo.name);
      }
      await api.post(`/user`, data).then((response) => {
        toast.success("Advogado criado com sucesso")
        setIsOpen(false)
        loadUsers()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editUser() {
    setIsLoadingModal(true)
    if (!name || !email){
      toast.warning("Nome e email são obrigatórios");
    } else {
      const data = new FormData();
      data.append('name', name);
      data.append('email', email);
      data.append('observation', observation);
      data.append('oab', oab);
      data.append('role', "LAWYER");
      if (photo.name) {
        data.append('file', photo, photo.name);
      }
      await api.put(`/user/${id}`, data).then((response) => {
        toast.success("Advogado editado com sucesso")
        setIsOpen(false)
        loadUsers()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteUser(user) {
    await api.delete(`/user/${user.id}`).then(response => {
      toast.success("Advogado deletado com sucesso")
      loadUsers()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  return (
    <Container>
      <Title>
        <h2>Advogados</h2>
        <div>
            <Button type="primary" onClick={() => {
              setId("")
              setName("")
              setEmail("")
              setOab("")
              setPassword("")
              setPhoto("")
              setObservation("")
              setIsOpen(true)
            }}>
            <MdAdd size="20" color="#fff" />
            <span>Novo Advogado</span>
          </Button>
        </div>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <MdWork color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={usersTotal} />
            <p>Total</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {users.length ? (
              <>
              <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td style={{ width: 50 }}>
                      </td>
                      <td>Nome</td>
                      <td>Email</td>
                      <td style={{minWidth: 120}}>OAB</td>
                      <td style={{ width: 80 }}>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      users.map(user => (
                        <tr key={user.id}>
                          <td style={{ width: 40 }}>
                            <img style={{ width: 30, height: 30, borderRadius: 2}} src={user.photo_url || userPhoto} />
                          </td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.oab}</td>
                          <td style={{width: 120}}>
                            <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                              <MdEdit
                                onClick={() => {
                                  setId(user.id)
                                  setName(user.name)
                                  setOab(user.oab)
                                  setObservation(user.observation)
                                  setEmail(user.email)
                                  setPhoto({photo_url: user.photo_url})
                                  setIsOpen(true)
                                }}
                                  style={{ cursor: "pointer", marginLeft: 10 }}
                                  size={22}
                                  color={"#001B22"}
                              />
                              <MdDelete
                                onClick={() => {
                                  confirm({
                                    title: 'Deseja excluir o Advogado?',
                                    icon: <ExclamationCircleFilled/>,
                                    content: `Após essa ação, o Advogado ${user.name} será excluido.`,
                                    onOk() {
                                      deleteUser(user);
                                    },
                                    onCancel() {},
                                      cancelText: "Cancelar",
                                  });
                                }}
                                  style={{ cursor: "pointer", marginLeft: 6 }}
                                  size={22}
                                  color={"#001B22"}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Scroll>
              <center style={{marginTop: 15}}>
                <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                  setPage(page - 1)
                }} 
                total={usersTotal} pageSize={30} 
                showTotal={(total) => `${total} advogados`}/>
              </center>
            </>
          ) : (
            <div className="error">
              <p>Nenhum advogado encontrado</p>
            </div>
          )}
        </>
      )}

      {isLoading && <Loader />}
      <Modal title={id ? "Editar Advogado" : "Novo Advogado"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={id ? "EDITAR ADVOGADO" : "CADASTRAR ADVOGADO"} cancelText="FECHAR" onOk={() => { 
        if (id) {
          editUser()
        } else {
          createUser()
        }  
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
        <div style={{ marginTop: 0, display: "flex", width: "100%", alignItems: "end", height: 120, borderRadius: 10, marginBottom: 25, marginBottom: 20, justifyContent: "center" }}>
            <img style={{ width: 120, marginLeft: 50, height: 120, borderRadius: 10, background: "#fff", }} src={photo.photo_url ? photo.photo_url : userPhoto} />
            <button style={{
              width: 50, height: 50, border: "none",
              background: "transparent"
            }}>
              <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                <MdAddPhotoAlternate color="#001B22" size={25} />
                <input id="avatar" style={{ display: "none" }} type="file" accept="image/png,image/jpeg"   onChange={(file) => {
                  let photo = file.target.files[0]
                  photo.photo_url = URL.createObjectURL(file.target.files[0])
                  setPhoto(photo)
                }
                }
                />
              </label>
            </button>
          </div>
          <ViewInput>
            <p>Nome*</p>
            <Input
              placeholder="nome"
              value={name}
              onChange={(event) => setName(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Email*</p>
            <Input
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)} />
          </ViewInput>
          {!id && (
            <ViewInput>
              <p>Senha*</p>
              <Input
                placeholder="Email"
                value={password}
                onChange={(event) => setPassword(event.target.value)} />
            </ViewInput>
          )}
          <ViewInput>
            <p>OAB*</p>
            <Input
              placeholder="oab"
              value={oab}
              onChange={(event) => setOab(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Observação</p>
            <TextArea
              placeholder="deixe uma observação"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={observation}
              onChange={(event) => setObservation(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
    </Container>
  );
};

export default Advogados;
